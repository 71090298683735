import { showJoinCommunityPopup } from '../common/store/actions/join-community';
import {
  biOpenReviewActions,
  biSelectReviewAction,
  biShowZeroReviewsEmptyState,
  biShowFallbackState,
  biClickShowMoreBtn,
  biAppLoaded,
  biClickLeaveReviewBtn,
  biClickReplyBtn,
  biClickHelpfulBtn,
  biChangeSorting,
  biOpenSorting,
  biOpenFiltering,
  biChangeFiltering,
  biOpenReplyActions,
  biClickShowMyReview,
  biSelectReplyAction,
  biShowFilteredToZeroEmptyState,
  biCloseMediaModal,
  biOpenMediaModal,
  biClickPaginationBtn,
  biClickShowAllReviewsBtn,
  biClickPublishReview,
  biClickPublishReply,
  biCancelCreatingReview,
  biCancelCreatingReply,
  biMediaUploaded,
  biMediaDeleted,
  biShowFailedToLoadEmptyState,
  biShowPendingReviewModal,
  biClickClosePendingReviewModal,
  biReviewSubmittedForApproval,
  biFormValidationError,
  biAppIsInViewport,
} from '../common/store/actions/bi-actions';
import {
  fetchReviewsInitial,
  refetchReviewsInitial,
  fetchDeepLink,
  fetchNextReviews,
  fetchPrevReviews,
  showAllReviews,
  changeOrdering,
  changeRatingFilter,
  changeOrderingAndFilter,
  changePageSize,
  unmountResource,
  focusRoot,
} from '../common/store/reviews/paging/reviews-paging-actions';
import { voteOnReview } from '../common/store/reviews/votes/review-vote-actions';
import {
  createReview,
  editReview,
  cancelCreatingReview,
  cancelEditingReview,
  updateReview,
  deleteReview,
  callToActionClicked,
  openReviewForm,
  closeReviewForm,
} from '../common/store/reviews/reviews/reviews-crud-actions';
import {
  createReply,
  updateReply,
  deleteReply,
  cancelCreatingReply,
  cancelEditingReply,
  editReply,
  initReply,
} from '../common/store/reviews/replies/replies-crud-actions';
import { showToast } from '../common/store/reviews/show-toast-action';
import { requestLogin } from '~reviews/common/store/actions/request-login';

export const controllerActions = {
  fetchReviewsInitial,
  refetchReviewsInitial,
  fetchDeepLink,
  fetchNextReviews,
  fetchPrevReviews,
  createReview,
  editReview,
  cancelCreatingReview,
  cancelEditingReview,
  updateReview,
  createReply,
  updateReply,
  deleteReply,
  cancelCreatingReply,
  cancelEditingReply,
  editReply,
  initReply,
  deleteReview,
  showAllReviews,
  changeOrdering,
  changeRatingFilter,
  changeOrderingAndFilter,
  changePageSize,
  unmountResource,
  showJoinCommunityPopup,
  biAppLoaded,
  biAppIsInViewport,
  biClickLeaveReviewBtn,
  biClickReplyBtn,
  biClickHelpfulBtn,
  biOpenSorting,
  biOpenFiltering,
  biChangeSorting,
  biChangeFiltering,
  biOpenReviewActions,
  biOpenReplyActions,
  biClickShowMyReview,
  biSelectReviewAction,
  biSelectReplyAction,
  biShowFilteredToZeroEmptyState,
  biShowZeroReviewsEmptyState,
  biShowFailedToLoadEmptyState,
  biShowFallbackState,
  biCloseMediaModal,
  biOpenMediaModal,
  biClickPaginationBtn,
  biClickShowAllReviewsBtn,
  biClickPublishReview,
  biFormValidationError,
  biClickPublishReply,
  biCancelCreatingReview,
  biCancelCreatingReply,
  biMediaUploaded,
  biMediaDeleted,
  biClickShowMoreBtn,
  biShowPendingReviewModal,
  biReviewSubmittedForApproval,
  biClickClosePendingReviewModal,
  showToast,
  callToActionClicked,
  openReviewForm,
  focusRoot,
  closeReviewForm,
  voteOnReview,
  requestLogin,
};
