import { ModerationStatus } from '@wix/ambassador-reviews-v1-enriched-review/types';
import {
  CREATE_REVIEW_REQUEST,
  ReviewsCrudAction,
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_FAILURE,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_FAILURE,
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_FAILURE,
  UPDATE_REVIEW_SUCCESS,
  CANCEL_CREATING_REVIEW,
  EDIT_REVIEW,
  CANCEL_EDITING_REVIEW,
  CALL_TO_ACTION_CLICKED,
  OPEN_REVIEW_FORM_SUCCESS,
  CLOSE_REVIEW_FORM_SUCCESS,
} from './reviews-crud-actions';
import { ReviewsStateReady } from '../reviews-types';
import { isOfType, unreachable } from '~/ts-utils';
import { ReviewState } from './review-state-types';
import { updateReviewState } from '../review-state-invariant-utils';

export function reviewsCrudReducer(
  state: ReviewsStateReady,
  action: ReviewsCrudAction,
): ReviewsStateReady {
  switch (action.type) {
    case CREATE_REVIEW_REQUEST: {
      if (state.userReview.type === 'CREATED') {
        return state;
      }
      return {
        ...state,
        userReview: {
          type: 'NOT_CREATED',
          form: { type: 'REVIEW_CREATE_PENDING' },
        },
      };
    }

    case CREATE_REVIEW_SUCCESS: {
      const { review } = action.payload;
      const { userReview, ratingsBreakdown } = state;
      if (userReview.type === 'CREATED') {
        return state;
      }
      const formState = userReview.form;
      if (formState.type !== 'REVIEW_CREATE_PENDING') {
        return state;
      }
      const rating = review.content.rating;
      const reviewState: ReviewState = {
        type: 'REVIEW_READY',
        review,
        reviewId: review.id,
        votes: {
          currentUserVote: { type: 'NEUTRAL' },
          scoresWithoutUserVote: {
            upvotes: 0,
            downvotes: 0,
          },
        },
      };
      return {
        ...state,
        userReview: { type: 'CREATED', review: reviewState },
        ratingsBreakdown: {
          ...ratingsBreakdown,
          ...(review.moderationStatus === ModerationStatus.APPROVED
            ? { [rating]: (ratingsBreakdown[rating] || 0) + 1 }
            : {}),
        },
        reviewList: (() => {
          const { reviewList } = state;
          switch (reviewList.type) {
            case 'READY':
              return {
                ...state.reviewList,
                prependCurrentUserReview: true,
              };
            case 'DEEP_LINK':
            case 'PENDING':
            case 'ONLY_USER_REVIEW':
              return {
                type: 'ONLY_USER_REVIEW' as const,
              };

            default:
              throw unreachable(reviewList);
          }
        })(),
        effects: {
          ...state.effects,
          ...(review.moderationStatus === 'APPROVED'
            ? {
                highlight: { type: 'CRUD_SUCCESS', id: review.id, timestamp: Date.now() },
                toast: {
                  isHidden: true,
                  skin: 'success',
                  message: { type: 'TRANSLATION', key: 'toast.review-published' },
                  timestamp: Date.now(),
                },
              }
            : { prompt: { type: 'MODERATION_PROMPT', id: review.id, timestamp: Date.now() } }),
        },
      };
    }

    case CREATE_REVIEW_FAILURE: {
      const { error } = action.payload;

      if (state.userReview.type === 'CREATED') {
        return state;
      }
      const currentState = state.userReview.form;
      if (currentState.type !== 'REVIEW_CREATE_PENDING') {
        return state;
      }
      return {
        ...state,
        userReview: {
          type: 'NOT_CREATED',
          form: {
            type: 'REVIEW_CREATE_ERROR',
            error,
          },
        },
      };
    }

    case CANCEL_CREATING_REVIEW: {
      if (state.userReview.type === 'CREATED') {
        return state;
      }
      const currentFormState = state.userReview.form;
      if (currentFormState.type === 'HIDDEN') {
        return state;
      }
      return {
        ...state,
        userReview: {
          type: 'NOT_CREATED',
          form: { type: 'HIDDEN' },
        },
      };
    }

    case CANCEL_EDITING_REVIEW: {
      const { reviewId } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_EDITING', current)
            ? current
            : {
                // we do not spread currentState to not pass content field
                type: 'REVIEW_READY',
                review: current.review,
                reviewId: current.reviewId,
                votes: current.votes,
              },
      });
    }

    case EDIT_REVIEW: {
      const { reviewId } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_READY', current)
            ? current
            : {
                ...current,
                type: 'REVIEW_EDITING',
              },
      });
    }

    case UPDATE_REVIEW_REQUEST: {
      const { reviewId, content } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_EDITING', current)
            ? current
            : {
                type: 'REVIEW_UPDATE_PENDING',
                reviewId: current.reviewId,
                review: current.review,
                content,
                votes: current.votes,
              },
      });
    }

    case UPDATE_REVIEW_FAILURE: {
      const { error, reviewId } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_UPDATE_PENDING', current)
            ? current
            : { ...current, type: 'REVIEW_READY' },
        updateEffects: () => ({
          highlight: { type: 'CRUD_ERROR', id: reviewId, timestamp: Date.now() },
          toast:
            error.type === 'SILENT'
              ? state.effects.toast
              : { skin: 'error', message: error, timestamp: Date.now() },
        }),
      });
    }
    case UPDATE_REVIEW_SUCCESS: {
      const { review, reviewId } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_UPDATE_PENDING', current)
            ? current
            : { ...current, type: 'REVIEW_READY', review },
        updateEffects: () =>
          review.moderationStatus === 'APPROVED'
            ? {
                highlight: { type: 'CRUD_SUCCESS', id: reviewId, timestamp: Date.now() },
                toast: {
                  skin: 'success',
                  message: { type: 'TRANSLATION', key: 'toast.review-updated' },
                  timestamp: Date.now(),
                },
              }
            : { prompt: { type: 'MODERATION_PROMPT', id: reviewId, timestamp: Date.now() } },
      });
    }
    case DELETE_REVIEW_REQUEST: {
      const { reviewId } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_READY', current)
            ? current
            : { ...current, type: 'REVIEW_DELETE_PENDING' },
      });
    }
    case DELETE_REVIEW_FAILURE: {
      const { reviewId, error } = action.payload;

      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_DELETE_PENDING', current)
            ? current
            : { ...current, type: 'REVIEW_READY' },
        updateEffects: () => ({
          highlight: { type: 'CRUD_ERROR', id: reviewId, timestamp: Date.now() },
          toast:
            error.type === 'SILENT'
              ? state.effects.toast
              : { skin: 'error', message: error, timestamp: Date.now() },
        }),
      });
    }
    case DELETE_REVIEW_SUCCESS: {
      const { reviewId } = action.payload;
      return updateReviewState({
        state,
        reviewId,
        updateReview: (current) =>
          !current || !isOfType('REVIEW_DELETE_PENDING', current) ? current : undefined,
        updateEffects: () => ({
          toast: {
            skin: 'success',
            message: { type: 'TRANSLATION', key: 'toast.review-deleted' },
            timestamp: Date.now(),
          },
        }),
      });
    }
    case CALL_TO_ACTION_CLICKED: {
      if (state.userReview.type === 'CREATED' && action.payload.button === 'view') {
        const isReviewInList =
          state.reviewList.type === 'READY' &&
          (state.reviewList.chunk.reviewIds.includes(state.userReview.review.reviewId) ||
            state.reviewList.prependCurrentUserReview);
        return {
          ...state,
          reviewList: isReviewInList ? state.reviewList : { type: 'ONLY_USER_REVIEW' },
          effects: {
            ...state.effects,
            highlight: {
              type: isReviewInList ? 'SCROLL_TO_REVIEW' : 'FOCUS_REVIEW',
              id: state.userReview.review.reviewId,
              timestamp: Date.now(),
            },
          },
          config: isReviewInList
            ? state.config
            : // reset rating filter when going to 'ONLY_USER_REVIEW' state
              {
                ...state.config,
                pagination: {
                  ...state.config.pagination,
                  ratingFilter: undefined,
                },
              },
        };
      }
      if (
        state.userReview.type === 'NOT_CREATED' &&
        action.payload.button === 'create' &&
        state.userReview.form.type === 'HIDDEN'
      ) {
        return {
          ...state,
          userReview: {
            type: 'NOT_CREATED',
            form: { type: 'INITIAL' },
          },
        };
      }

      return state;
    }
    case OPEN_REVIEW_FORM_SUCCESS: {
      return {
        ...state,
        userReview: {
          type: 'NOT_CREATED',
          form: { type: 'INITIAL' },
        },
      };
    }
    case CLOSE_REVIEW_FORM_SUCCESS: {
      return {
        ...state,
        userReview: {
          type: 'NOT_CREATED',
          form: { type: 'HIDDEN' },
        },
      };
    }
    default:
      throw unreachable(action);
  }
}
