import thunk from '@wix/redux-thunk';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { identity } from 'lodash';
import { applyMiddleware, createStore, Middleware } from 'redux';
import { DevToolsApi } from '../common/dev-tools-types';
import { initBiMiddleware } from '../common/store/middleware/bi-middleware';
import { rootReducer } from '../common/store/reducer';
import { BeforeCrud } from '../common/store/reviews/reviews-before-crud-interceptor';
import { ExtraThunkArguments, State } from '../common/store/store-types';
import { InstanceManager } from './lib/get-instance';
import { createReviewsApi } from './lib/reviews-api';

export function createReduxStore({
  flowApi,
  biMiddleware,
  isSSR,
  devToolsApi,
  instanceManager,
  reviewsWatcherMidlleware,
  ratingsWatcherMiddleware,
  executeBeforeCrud,
  logNetwork,
}: {
  flowApi: ControllerParams['flowAPI'];
  biMiddleware: ReturnType<typeof initBiMiddleware>;
  isSSR: boolean;
  devToolsApi: DevToolsApi;
  instanceManager: InstanceManager;
  reviewsWatcherMidlleware: Middleware;
  ratingsWatcherMiddleware: Middleware;
  executeBeforeCrud: BeforeCrud;
  logNetwork: 'all' | 'error' | 'none';
}) {
  const composeEnhancers = identity;
  const { httpClient } = flowApi;
  const { wixCodeApi, platformAPIs, compId } = flowApi.controllerConfig;

  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        reviewsWatcherMidlleware,
        ratingsWatcherMiddleware,
        biMiddleware,
        thunk.withExtraArgument<ExtraThunkArguments, State>({
          wixCodeApi,
          platformAPIs,
          compId,
          wixReviewsApi: createReviewsApi({
            getCrudDevState: () => devToolsApi.getState().crudState,
            getFetchDevState: () => devToolsApi.getState().middlewareState,
            petriOvr: wixCodeApi.location.query.petri_ovr,
            siteRevision: wixCodeApi.site.revision,
            isSSR: wixCodeApi.window.rendering.env === 'backend',
            httpClient,
            showMockData: flowApi.environment.isEditor || flowApi.environment.isPreview,
            logNetwork,
          }),
          instanceManager,
          executeBeforeCrud,
        }),
      ),
    ),
  );
}

export type ReduxStore = ReturnType<typeof createReduxStore>;
